import React, { Component } from "react";
import FrontFooter from "./common/Front-Footer";
import FrontWrapper from "./common/FrontWrapper";
import LazyImage from "../common/lazyImage";
class Services extends Component {
	render() {
		return (
			<FrontWrapper location={this.props.location}>
				<div className="scroll-content-wrapper" data-scrollbar>
					<div className="page-main page-current">
						<div className="page-toload legacy-page" data-bodyclassname="legacy">
							<main className="page-content" role="main">
								<section className="section-container our-story our-service-bn">
									<h2 className="show-text head padd-max js-type-animation-alt">
										Our Services
									</h2>
									<p>
										Hari Krishna Group is a key player in the Diamond Industry.
										We don a number of hats such as Diamond Manufacturer,
										Diamond Exporter, Diamond Jewellery Manufacturer, Diamond
										Jewellery Exporter and Diamond Jewellery Retailer, setting
										high standards in each sphere of activity.
									</p>
									<div className="our-service-dim mt-100 mb-50">
										<div className="export text-center mb-50 mb-xs-20">
											<h4>Diamond Jewellery Exporter</h4>
										</div>
										<div className=" export mb-50 mb-xs-20 d-flex align-items-center j-space-between">
											<div className="export-inner-three text-right text-xs-center mb-xs-20">
												<h4>Diamond Manufacturer</h4>
											</div>
											<div className="export-inner-three text-center mb-xs-20">
												<LazyImage
													src={require("../../../static/assets/img/static/diamond.png")}
													alt=""
												/>
											
											</div>
											<div className="export-inner-three text-left text-xs-center">
												<h4>Diamond Exporter</h4>
											</div>
										</div>
										<div className="export text-center">
											<h4>Diamond Jewellery Manufacturer</h4>
										</div>
									</div>
									<div style={{ textAlign: "-webkit-center" }}>
										<LazyImage
											src={require("../../../static/assets/svg/index/down-arrow.svg")}
											alt=""
										/>
									</div>
								</section>

								<section className="service-bg-light services-listing margin-bottom-0 margin-top-0">
									<div className="services-listing-heading m-b-60 mb-xs-20">
										<h3>Diamond Manufacturer</h3>
										<p>Bringing Life To Raw Beauty!</p>
									</div>
									<div className="container d-flex align-items-center full-width-container-xs container-md full-width-container-sm flex-wrap-xs">
										<div className="service-list-logo service-bg-white">
											<LazyImage
												src={require("../../../static/assets/svg/index/logo-e.svg")}
												alt=""
												width={950}
											/>
										</div>
										<div className="service-list-content text-center-xs">
											<p>
												A state-of-the-art factory sprawled over a whopping
												1 lakh square feet area with world-class equipments
												and over 6000 efficient workers, this is where the
												rough and raw diamonds are polished and perfected
												into the shimmering marvel we all come to admire and
												adorn some day.
											</p>
										</div>
									</div>
								</section>

								<section className="service-bg-white services-listing margin-bottom-0 margin-top-0">
									<div className="services-listing-heading m-b-60 mb-xs-20">
										<h3>Diamond Exporter</h3>
										<p>Taking Quality To The World!</p>
									</div>
									<div className="container d-flex align-items-center full-width-container-xs container-md full-width-container-sm flex-wrap-xs">
										<div className="service-list-logo service-bg-light">
											<LazyImage
												src={require("../../../static/assets/svg/index/logo-e.svg")}
												alt=""
												width={950}
											/>
										</div>
										<div className="service-list-content text-center-xs">
											<p>
												Hari Krishna Exports Pvt. Ltd is one of the
												industry’s prominent Diamond Exporters with presence
												and alliances across the globe particularly in USA,
												Hong Kong, UK, Israel, UAE and Belgium. We deal in
												the business of wholesale certified and
												non-certified diamonds, providing profitable deals
												to our valuable clients
											</p>
										</div>
									</div>
								</section>

								<section className="service-bg-light services-listing margin-bottom-0 margin-top-0">
									<div className="services-listing-heading m-b-60 mb-xs-20">
										<h3>Diamond Jewellery Manufacturer</h3>
										<p>Some Things Last Forever!</p>
									</div>
									<div className="container d-flex align-items-center full-width-container-xs container-md full-width-container-sm flex-wrap-xs">
										<div className="service-list-logo service-bg-white">
											<div className="text-center text-center-xs">
												<LazyImage
													src={require("../../../static/assets/svg/index/logo-fname.svg")}
													alt=""
													width={130}
												/>
											</div>
											<div className="mt-30 d-flex align-items-center j-space-between">
												<div style={{ marginRight: 15 }}>
													<LazyImage
														src={require("../../../static/assets/svg/index/diamond-story.svg")}
														alt=""
														width={600}
													/>
												</div>
												<div style={{ marginLeft: 15 }}>
													<LazyImage
														src={require("../../../static/assets/svg/index/kishna.svg")}
														alt=""
														width={600}
													/>
												</div>
											</div>
										</div>
										<div className="service-list-content">
											<p>
												Like Diamond Jewellery for instance, and the
												precious moments they are meant to capture. Our
												inspired designers skilfully cut, design and
												innovate with various themes and trends. Add to it
												the state-of-the-art technology at our manufacturing
												units and our skilled and experienced workforce, our
												jewellery comes perfectly crafted to make a lasting
												impression.
											</p>
										</div>
									</div>
								</section>

								<section className="service-bg-white services-listing margin-bottom-0 margin-top-0">
									<div className="services-listing-heading m-b-60 mb-xs-20">
										<h3>Diamond Jewellery Exporter</h3>
										<p>Spreading The Radiance Around!</p>
									</div>
									<div className="container d-flex align-items-center flex-wrap-xs full-width-container-xs container-md full-width-container-sm">
										<div className="service-list-logo service-bg-light">
											<LazyImage
												src={require("../../../static/assets/svg/index/logo-e.svg")}
												alt=""
												width={1500}
											/>
										</div>
										<div className="service-list-content">
											<p>
												Unique designs and stylescrafted with the highest
												qualityare exported to theimage conscious
												international customers. Our wide array of jewellery
												designs have fetched us unanimous praise in the
												global market. We have a penchant for coming up with
												new designs on a regular basis. Thus, offering
												innovation and new trends has ensured recognition as
												one of the leading Jewellery Exporters from India to
												USA, Hong Kong, UK, Israel, UAE and Belgium.
											</p>
										</div>
									</div>
								</section>

								<FrontFooter />
							</main>
							<div className="preload" aria-hidden="true" />
						</div>
					</div>
					<div className="page-main page-next" aria-hidden="true" />
					<div className="page-main page-prev" aria-hidden="true" />
					<footer className="footer" role="contentinfo" />
				</div>
			</FrontWrapper>
		);
	}
}

export default Services;
